"use strict";

import { getScrollBarWidth } from "./tools";

const scroolbarWidth = getScrollBarWidth();
const hamburger = document.querySelector(".hamburger");
const overlay = document.querySelector(".overlay");
const navigation = document.querySelector("header nav");

hamburger.addEventListener("click", (event) => {
  event.target.classList.toggle("cross");
  overlay.classList.toggle("visible");
  navigation.classList.toggle("visible");
  document.body.classList.toggle("no-scroll");

  if (scroolbarWidth > 0) {
    document.body.classList.toggle("no-scroll-desktop");
  }
});

navigation.addEventListener("click", (event) => {
  if (event.target.tagName !== "A") return;
  const style = window.getComputedStyle
    ? window.getComputedStyle(hamburger, null)
    : hamburger.currentStyle;
  if (style.visibility === "visible") {
    hamburger.click();
  }
});
